var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-layout',[_c('content-section',{attrs:{"spacing":false}},[_c('div',{staticClass:"content-spacing"},[_c('div',{staticClass:"grid grid-cols-1 mb-10 md:grid-cols-2"},[_c('div',[_c('title-plus',{attrs:{"title":"Refund Request","hide-plus":true}})],1),_c('div',{staticClass:"flex md:justify-end"},[_c('date-range-picker',{on:{"apply-date":_vm.onApplyFilterDateRange,"cancel-date":_vm.onApplyFilterDateRange}})],1)])]),(_vm.$acl.canView('transaction'))?[_c('FSTable',{attrs:{"fst-id":"refundRequest","endpoint":_vm.getEndpoint,"headers":_vm.getTableHeaders,"exportFor":"refundRequest"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
return [_vm._l((data),function(item,itemIndex){return [[_c('FSTableRow',{key:itemIndex,attrs:{"text-fallback-always":""},on:{"meta":function (e) { return (_vm.indexMetaData = e); }}},[_c('FSTableRowItem',{attrs:{"text":item.id,"truncate":-5}}),_c('FSTableRowItem',[(item.rider)?_c('router-link',{staticClass:"font-extrabold text-blue-600 capitalize",attrs:{"to":{
                      name: 'ViewRiderUserProfile',
                      params: { id: item.rider.id },
                    },"target":"_blank"}},[_vm._v(" "+_vm._s(item.rider.full_name)+" ")]):_c('span',[_vm._v(" -- ")])],1),_c('FSTableRowItem',{attrs:{"text":("" + (item.currency.symbol) + (item.requested_amount))}}),_c('FSTableRowItem',{attrs:{"text":item.refunded_amount
                      ? ("" + (item.currency.symbol) + (item.refunded_amount))
                      : '--'}}),_c('FSTableRowItem',{attrs:{"text":item.refundable_amount
                      ? ("" + (item.currency.symbol) + (item.refundable_amount))
                      : '--'}}),_c('FSTableRowItem',[(item.reference_ids)?_vm._l((item.reference_ids),function(refId,refIdIndex){return _c('span',{key:refIdIndex,staticClass:"text-blue-500 px-1"},[_c('a',{attrs:{"target":"_blank","href":("https://dashboard.stripe.com/payments/" + refId)}},[_vm._v(_vm._s(_vm.truncate(refId, -5, '')))]),_c('span',{staticClass:"cursor-pointer text-gray-500",on:{"click":function($event){return _vm.copyTextToClipboard(
                            ("https://dashboard.stripe.com/payments/" + refId)
                          )}}},[_c('i',{staticClass:"ml-2 far fa-copy"})])])}):_c('span',[_vm._v("--")])],2),_c('FSTableRowItem',{attrs:{"text":item.created_at,"date":""}}),_c('FSTableRowItem',[_c('XStatus',{attrs:{"text":item.status_display_text,"variant":_vm.getVariant(item.status)}})],1),_c('FSTableRowItem',[(item.status === 0)?_c('div',{staticClass:"flex items-center "},[[_c('t-dropdown',{attrs:{"variant":"smActions"},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
                  var mousedownHandler = ref.mousedownHandler;
                  var focusHandler = ref.focusHandler;
                  var blurHandler = ref.blurHandler;
                  var keydownHandler = ref.keydownHandler;
return [_c('button',{staticClass:"focus:outline-none",attrs:{"aria-label":"More Actions","aria-haspopup":"true"},on:{"mousedown":mousedownHandler,"focus":focusHandler,"blur":blurHandler,"keydown":keydownHandler}},[_c('svg',{staticClass:"w-6 h-5 mt-1 text-oGray hover:text-oDark",attrs:{"fill":"none","stroke":"currentColor","viewBox":"0 0 24 24","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"}})])])]}},{key:"default",fn:function(ref){
                  var hide = ref.hide;
return [_c('div',{staticClass:"py-1 mt-px bg-white rounded-md shadow-md cursor-pointer",on:{"click":function($event){return hide()}}},[_c('t-dropdown-item',{on:{"click":function($event){return _vm.onRefundReq(item)}}},[_vm._v(" Refund ")])],1)]}}],null,true)})]],2):_vm._e()])],1)]]})]}}],null,false,3771523019)})]:_c('div',{staticClass:"py-5 font-bold text-center text-gray-600"},[_vm._v(" "+_vm._s(_vm.$t('components.acl.doNotHavePermission'))+" ")])],2),_c('RefundRequestPopup',{on:{"refresh":function($event){return _vm.$store.dispatch('fsTable/fetchData')}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }